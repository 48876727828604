.voice-setting {
  background-color: var(--background-color);
  width: webkit-fill-available;
  height: webkit-fill-available;
  margin-left: 23px;
  margin-top: 10px;
}

.voice-body {
  margin-left: 4vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 90%;
  height: 98%;
  overflow-y: scroll;
}

@media (max-width: 700px) {
  .voice-body {
    margin-left: 20%;
  }
}
