.voci-cassa {
  margin-left: -1vw;
  margin-top: 6vw;
  width: webkit-fill-available;
}
.head-voci-cassa {
  width: 18vw;
  /* margin-right: 1vw; */
  font-size: 1.5vw;
  height: 3vw;
  padding: 0.2vw;
  color: #0a0101;
  text-align: center;
  margin-left: 1.6vw;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.head-voci-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.5vw;
  background-color: white;
  height: 4vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* border-top-left-radius: 5px;
  border-top-right-radius: 5px; */
  margin-bottom: 1vw;
}

.totale-cassa {
  display: flex;
  margin-bottom: 1vw;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  /* border-radius: 5px; */
  background-color: white;
  margin-left: 1.5vw;
  height: 4vw;
}
.head-tot-cassa {
  width: 13vw;
  font-size: 1.3vw;
  background-color: #4c79d8;

  padding: 0.2vw;
  color: white;
  text-align: center;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.tot-cassa {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 1.7vw;
  width: 40%;
}
.separatore {
  width: 26vw;
  border-bottom: 4px solid #2b3041;
  margin-left: 1.6vw;
  border-radius: 10px;
  margin-bottom: 1vw;
}
.body-voci-cassa {
  overflow-y: scroll;
  height: 49vh;
}
@media (max-width: 700px) {
  .body-voci-cassa {
    display: none;
  }
  .voci-cassa {
    margin-left: -52px;
    margin-top: 50px;
  }
  .separatore {
    display: none;
  }
  .head-voci-container {
    display: none;
  }
  .totale-cassa {
    display: flex;
    margin-top: 2em;
    margin-bottom: -6vw;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 5px;
    background-color: white;
    height: 6vh;
    margin-left: -65vw;
    width: 70vw;
  }
  .tot-cassa {
    font-size: 120%;
    width: 50%;
  }
  .head-tot-cassa {
    font-size: 100%;
    width: 30%;
  }
}
