.t-body-mov-cassa {
  display: flex;
  height: 2.5vw;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 0.2vw;
  margin-bottom: 0.2vw;
  border-bottom: 2px solid lightgray;
}

.t-body-mov {
  margin-right: 3vw;
  width: 7.5vw;
  width: 50%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;
  font-size: 1.15vw;
}

.ico-ora-mov {
  font-size: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-right: 0.5vw;
}
