.div_chart_single_stats {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 380px;
  padding: 10px;
  margin: 10px;
  overflow-y: auto;
  background-color: white !important;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
