.head-crediti {
  display: flex;
  height: 4vw;
  /* margin-bottom: 1vw; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* background-color: white; */
  /* border-top-left-radius: 5px;
    border-top-right-radius: 5px; */
  min-width: 460px;
  max-width: none !important;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.head-crediti-nome {
  /* margin-right: 1.5vw; */
  width: 15vw;
  font-size: 1.6vw;
  /* background-color: #2b3041; */
  /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
  /* border-radius: 15px; */
  padding: 0.2vw;
  color: #0a0101;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.head-crediti-cg {
  margin-right: 1vw;
  width: 5vw;
  font-size: 1.6vw;
  text-align: center;
  /* background-color: #2b3041; */
  /* border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
  padding: 0.2vw;
  color: #0a0101;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-crediti-ct {
  margin-right: 0.2vw;
  width: 5vw;
  font-size: 1.6vw;
  text-align: center;
  /* background-color: #2b3041;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px; */
  padding: 0.2vw;
  color: #0a0101;
  display: flex;
  align-items: center;
  justify-content: center;
}

.body-crediti {
  overflow-y: auto;
  /* height: 33.5vw; */
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  height: 74vh;
  /* border-bottom-right-radius: 5px ;
    border-bottom-left-radius: 5px ; */
  border-radius: 5px;
}
.crediti {
  margin-left: 47px;
}
.head-mobile-crediti {
  display: none;
}
.mobile-none {
  display: flex;
}

@media (max-width: 700px) {
  .crediti {
    /* margin-left: 13vw; */
    margin-left: 0px;
    margin-top: 230px;
    width: 89%;
  }
  .movimenti-cassa {
    display: none;
  }
  .head-crediti {
    height: 100px;
    min-width: -webkit-fill-available;
  }

  .head-crediti-nome {
    height: 5vh;
    width: 50%;
    font-size: 100%;
  }
  .head-crediti-cg {
    height: 5vh;
    width: 20%;
    font-size: 100%;
  }
  .head-crediti-ct {
    height: 5vh;
    width: 20%;
    font-size: 100%;
  }
  .btn-add-credito {
    font-size: 5vh;
    margin-right: 3vh;
    margin-left: none;
    color: #2b3041;
    height: 5vh;
    width: 5vh;
  }
  .mobile-none {
    display: none;
  }
  .head-mobile-crediti {
    display: flex;
    flex-direction: column;
    max-width: -webkit-fill-available !important;
  }
  .btn-container {
    margin-top: 10vh;
    display: flex;
    flex-direction: row;
  }
  .body-crediti {
    height: 90%;
    width: 100%;
  }
  .mobile_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}
