.voice-card-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 40%;
  width: 20%;
  margin-left: 2%;
  margin-top: 2%;
  padding: 1%;
}
.voice-card-container-add {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  height: 40%;
  width: 20%;
  margin-left: 2%;
  margin-top: 2%;
  padding: 1%;
}
.text-group {
  color: var(--gray-light);
  margin-bottom: 5%;
}
.ico-delete-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 6%;
}
.input-group {
  margin-top: 5%;
}
.input-group input {
  text-align: center;
  width: 100%;
}

.btn-save-container {
  margin-top: 10%;
  display: flex;
  justify-content: center;
  height: 15%;
}
.btn-save-container button {
  border: none;
  border-radius: 5px;
  background-color: var(--primary-color);
  width: 60%;
  height: 100%;
  color: #fff;
}

@media (max-width: 700px) {
  .voice-card-container {
    height: 35%;
    width: 70%;
  }
  .voice-card-container-add {
    height: 35%;
    width: 70%;
  }
}
