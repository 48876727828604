.credit-card {
  display: flex;
  flex-direction: row;
  height: 2vw;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5vw;
  border-bottom: 2px solid lightgray;
}
.card-value {
  cursor: pointer;
}
.card-value:hover {
  font-weight: 1000;
  font-size: 1.4vw;
}

.delete-ico {
  font-size: 1.1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: #ec6a5e;
  z-index: 1;
}
.delete-ico-x {
  font-size: 1.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-left: -1.2vw;
  color: #ec6a5e;

  z-index: 0;
}
.delete-ico:hover {
  color: #45464a;
}

.value-card {
  width: 8vw;
  height: 2vw;
  display: flex;
  align-items: center;
}
.value-card:hover {
  font-weight: 1000;
  font-size: 1.4vw;
}
.name-card {
  font-size: 1.2vw;
  width: 10vw;
  display: flex;
  align-items: center;
}
.name-card:hover {
  font-weight: 1000;
  font-size: 1.4vw;
}

.cognome-card {
  font-size: 1.2vw;
  width: 10vw;
  display: flex;
  align-items: center;
}
.cognome-card:hover {
  font-weight: 1000;
  font-size: 1.4vw;
}
.check-style {
  width: 2vh !important;
}

@media (max-width: 700px) {
  .lista-crediti {
    height: 50vh;
  }
  .lista-crediti input {
    width: 80%;
    height: 8vw;
    font-size: 100%;
    margin-bottom: 1vw;
  }
  .credit-card {
    display: flex;
    flex-direction: row;
    height: 5vh;
    width: 100%;
    align-items: center;
  }
  .card-value {
    cursor: pointer;
    width: 80%;
  }
  .card-value:hover {
    font-weight: 1000;
    font-size: 100%;
  }
  .ico-credit-card {
    font-size: 7vw;
    margin-right: 1vw;
  }
  .ico-credit-card:hover {
    color: red;
    cursor: pointer;
    font-weight: 1000;
  }

  .value-card {
    width: 30%;
    height: 4vh;
  }
  .value-card:hover {
    font-weight: 1000;
    font-size: 100%;
  }
  .name-card {
    font-size: 90%;
    width: 70%;
  }
  .name-card:hover {
    font-weight: 1000;
    font-size: 100%;
  }

  .cognome-card {
    font-size: 90%;
    width: 50%;
  }
  .cognome-card:hover {
    font-weight: 1000;
    font-size: 100%;
  }
  .delete-ico {
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    color: #ec6a5e;
    z-index: 1;
    margin-left: 1.5vh;
  }
  .delete-ico-x {
    font-size: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    position: relative;
    margin-left: -2vh;
    color: #ec6a5e;

    z-index: 0;
  }
}
