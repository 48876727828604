@import url("https://fonts.googleapis.com/css2?family=Lato&display=swap");

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito", sans-serif;
}

.MuiDrawer-docked {
  background-color: #071358 !important;
}
.MuiDivider-root {
  background-color: #ffff !important;
}

.div_ {
  background-color: white !important;
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  height: 99%;
}
* {
  -ms-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
}

@media (max-width: 700px) {
  .div_ {
    max-width: -webkit-fill-available !important;
  }
}
