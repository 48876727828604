.container_single_card_stats {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 200px !important ;
  height: 120px;
  min-width: 200px;
  background-color: #fff;
  /* border-radius: 5px; */
  padding: 10px;
  margin-right: 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}
.footer_card_single_stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.footer_card_single_stats p {
  font-size: 12px;
  color: #2b3041;
}
.totale_card_single_stats {
  font-size: 30px;
  color: #2b3041;
  font-weight: normal;
}
.head_card_single_stats {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 13px;
}
