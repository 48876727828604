.container-lista-crediti {
  background-color: var(--background-color);
  height: 100vh;
  margin-left: 13px;
  margin-top: 10px;
}

.lista-crediti {
  margin-left: 4vw;
  display: flex;
  flex-direction: column;
  height: 92%;
  margin-right: 2vw;
  width: 45%;
  border-radius: 5px;
}
.lista-credit-head {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 1vw;
  height: 4vw;
  border-radius: 5px;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  width: 100%;
}

.lista-crediti input {
  width: 30vw;
  height: 3vw;
  font-size: 1.5vw;
  border: none;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.lista-crediti input:focus {
  outline: none;
}

.storico-crediti-head {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--primary-color);
  color: #fff;
  height: 3vw;
  width: 20vw;
  border-radius: 5px;
  font-size: 1.2vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.storico-crediti-body {
  height: 38vw;
  overflow-y: scroll;
  width: 20vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: white;
  height: 85%;
  margin-top: 0.5vw;
  border-radius: 5px;
  padding: 0.5vw;
}
.storico-render {
  background-color: #fff;
  width: 20vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0.5vw;
  margin-top: 0.5vw;
}
.storico-render p {
  margin-right: 1.4vw;
}
.lista-crediti-body {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  overflow-y: scroll;
  width: 100%;
}
.col-fm {
  cursor: pointer;
}
.table-fm {
  background-color: #fff;
}
.t-fm {
  display: block;
  height: 73.5vh;
  overflow-y: scroll;
  margin-left: 0.5vw;
}

@media (max-width: 700px) {
  .container-lista-crediti {
    margin-left: 9vw;
    display: flex;
    flex-direction: column;
    width: 85vh;
  }
  .lista-crediti input {
    width: 80%;
    height: 6vw;
    margin-bottom: 1vh;
    margin-left: 2vw;
    margin-top: 0.5vw;
  }
  .lista-credit-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin-bottom: 1vh;
    height: 4vh;
    border-radius: 5px;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    margin-top: 0.5vw;
  }
  .storico-crediti-container {
    margin-left: 4vw;
    width: 83vh;
    height: 46vh;
  }
  .storico-crediti-head {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: var(--primary-color);
    color: #fff;
    height: 4vh;
    margin-top: 1.4vw;
    width: 83vw;
    border-radius: 5px;
    font-size: 100%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .storico-crediti-body {
    width: 83vw;
    overflow-y: scroll;
  }
  .storico-render {
    background-color: #fff;
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .storico-render p {
    margin-right: 1.4vw;
    margin-bottom: 1vw;
  }
}
