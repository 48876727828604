.report {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--background-color);
  /* height: 100vh; */
  padding-top: 10px;
  padding-left: 100px;
  padding-right: 60px;
}
.report-container {
  padding-top: 0.5vw;
  margin-left: 4vw;
  display: flex;
  flex-direction: column;
  width: 70vw;
  overflow-x: scroll;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  height: 90%;
  border-radius: 5px;
}
.report-head-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
  border-bottom: 3px solid var(--secodary-color);
  padding-bottom: 0.5vw;
  margin-left: auto;
}
.head-voce-data {
  width: 10vw;
  height: 2.5vw;
  background-color: #2b3041;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  margin-right: 1vw;
}
.report-head {
  display: flex;
  flex-direction: row;
}
.report-footer-sub-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
  border-top: 3px solid var(--secodary-color);
  padding-top: 0.5vw;
  margin-left: auto;
}

.data-stats {
  margin-left: 2vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.p-div {
  width: 20vw;
  height: 2.5vw;
  margin-top: 0.5vw;
  background-color: var(--primary-color);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.pdf-div {
  color: white;
  width: 20vw;
  margin-left: 20px;
  background-color: #2b3041;
  border-color: #2b3041;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 3px solid #2b3041;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  cursor: pointer;
  transition: ease background-color 250ms;
  font-size: 25px;
  margin-top: 6px;
}
.pdf-div:hover {
  background-color: var(--primary-color);
  border: 3px solid var(--primary-color);
}
.pdf-div svg {
  font-size: 20px;
  color: #fff;
  margin-right: 1.5vw;
}
.link-pdf {
  text-decoration: none;
}
.pdf-page {
  margin-left: 2.8vw;
}
.ip-style {
  color: #fff !important;
  background-color: var(--secodary-color) !important;
}

.tot-netto {
  color: white;
  height: 4.5vw;
  width: 20vw;
  margin-top: 2.7vw;
  background-color: #2b3041;
  margin-bottom: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  font-size: 2vw;
}
.ipt-report {
  align-items: center !important;
  flex-direction: row !important;
  display: flex !important;
  margin-top: 10px !important;
}

.row_div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  font-size: 35px;
  color: white;
}
.row_date {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.row_date p {
  font-size: 18px;
  color: var(--secondary-color);
  margin-bottom: 0;
  margin-right: 5px;
}
.input_date {
  width: 150px;
  height: 33px;
  /* margin-left: 10px; */
  border-radius: 3px;
  border: 1px solid var(--secondary-color);
  font-size: 18px;
  text-align: center;
}

.stats_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 150px;
  width: 100%;
  padding-left: 10px;
  margin-top: 10px;
  overflow-y: auto;
}

.stats_container_totali {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  height: max-content;
  width: 99.5%;
  padding-left: 10px;
  padding: 10px;
  margin-left: 10px;
  overflow-y: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: white;
}
.contaienr_totale {
  padding-top: 10px;
  height: 80%;
  width: 100%;
}
.contaienr_stats_detail {
  height: 80%;
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
}
.head_tot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-left: 30px;
  height: 20%;
  width: 100%;
  font-size: 30px;
  font-weight: bold;
}
.col_data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 30px;
  height: 80%;
  width: 50%;
  font-size: 20px;
}
.row_data {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  font-size: 15px;
}
.row_data p {
  margin-right: 10px;
  margin-bottom: 7px;
  width: 180px;
  font-size: 17px;
}
.row_body_stats {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: 15px;
  margin-top: 10px;
}
