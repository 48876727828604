.save-ico-container {
  display: flex;
  flex-direction: row;
}
.save-ico {
  margin-right: 1vw;
  font-size: 1vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  color: var(--color-save-ico);
  /* color: var(--color-gray-ico); */
  z-index: 1;
}
.save-ico-x {
  margin-right: 1vw;
  font-size: 1.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  position: relative;
  margin-left: -2.15vw;
  color: var(--color-save-ico);
  z-index: 0;
}
.save-ico:hover {
  color: var(--color-gray-ico);
}

.save-btn {
  border: none;
  background-color: white;
}
