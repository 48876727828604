
:root {

    --background-color: #f1f3f9;
    --input-color: #fff;
    --input-border: #4c79d8;
    --input-background: #fff;
    --input-placeholder: #CBD1DC;
    --color-delete-ico:#ec6a5e;   
    --color-save-ico:#61c554;   
    --input-border-focus: #fd8c22;
    --color-gray-ico:#45464a;
    --group-color: var(--input-color);
    --group-border: var(--input-border);
    --group-background: #4c79d8;

    --group-color-focus: #fff;
    --group-border-focus: var(--input-border-focus);
    --group-background-focus: #fd8c22;

    --primary-color: #4c79d8;
    --secodary-color:#2b3041;

    --gray-light:rgb(147, 147, 147);

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.voce-cassa-card {
  background-color: white;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  margin-bottom: 0.3vw;
  height:2.5vw;
  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 1.5vw;
  // border-radius: 5px;
}
.nome{
    background-color:var(--primary-color);
    height:2.5vw;
    color: white;
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 1.5vw;
    width: 13vw;
    font-size: 1.2vw;
}
.input-value{
    background-color:white;
    height:2.5vw;
    color: var(--color-gray-ico);
    display: flex;
    align-items: center;
    text-align: center;
    padding-left: 0.5vw;
    border: none;
    width:8vw;
    font-size:1.2vw;
}
.input-value:focus{
    outline: none;
    background-color: whitesmoke;
}