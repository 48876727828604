.total-value {
  display: flex;
  /* border: #2b3041 solid 3px; */
  text-align: center;
  /* width: 9.7vw; */
  align-items: center;
  justify-content: flex-start;
  height: 4vw;
  font-size: 30px;
}
.head-tot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #4c79d8;
  /* justify-content: center; */
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* border-radius: 5px; */
  height: 80px;
  /* margin-bottom: 0.5vw; */
  width: 100%;
}
.icon_div {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
}
.div_tot {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  height: 60px;
  align-items: flex-start;
}
.tot-text {
  display: flex;
  height: 100%;
  /* height: 4vw; */
  color: #fff;
  text-align: start !important;
  justify-content: flex-start;
  align-items: center;
  font-size: 15px;
  text-wrap: nowrap;
}

@media (max-width: 700px) {
  .tot-text {
    height: 7vh;
    width: 30vw;
    font-size: 100%;
  }
  .total-value {
    height: 7vh;
    width: 30vw;
    font-size: 100%;
  }
  .head-tot {
    height: 7vh;
    width: 100%;
    font-size: 100%;
    /* margin-bottom: 1vh; */
  }
}
