.t-body {
  height: 2.5vw;
  height: 40px;
  display: flex;
  margin-top: 0.2vw;
  margin-bottom: 0.2vw;
  border-bottom: 2px solid lightgray;
}
.t-body-nome-cognome {
  width: 16vw;
  width: 55%;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  font-size: 1.15vw;
}
.t-body-cg {
  width: 6vw;
  width: 20%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.15vw;
}
.t-body-ct {
  width: 7vw;
  width: 20%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1.15vw;
  text-wrap: nowrap;
}
.ico-ora-credit {
  font-size: 1.4vw;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-left: 1%;
  margin-right: 1%;
}

@media (max-width: 700px) {
  .t-body {
    width: 116%;
    height: 7vh;
    display: flex;
    align-items: center;
  }
  .t-body-nome-cognome {
    height: 5vh;
    width: 45%;
    font-size: 80%;
  }
  .t-body-cg {
    height: 5vh;
    width: 20%;
    font-size: 90%;
  }
  .t-body-ct {
    height: 5vh;
    width: 20%;
    font-size: 90%;
    text-wrap: wrap;
  }
  .ico-ora-credit {
    display: none;
  }
}
