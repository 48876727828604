.movimenti-cassa {
  margin-left: 0.5vw;
  margin-right: -0.52vw;
  display: flex;
  flex-direction: column;
  /* border-left: 2px solid #2b3041; */
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  width: 17vw;
  /* height:92.5vh; */
}
.head-movimenti-cassa {
  width: 9vw;
  /* margin-right: 0vw; */
  font-size: 1.5vw;
  padding: 0.2vw;
  text-align: center;
  display: flex;
  align-items: center;
}
.head-movimenti {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  background-color: white;
  height: 4vw;
}

.body-mov-cassa {
  overflow-y: auto;
  /* height: 33.5vw; */
  /* height: 74vh; */
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  /* border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px; */
  border-radius: 5px;
}
.ico-head-cs {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.6vw;
  padding-left: 0.7vw;
  padding-right: 1vw;
}
